import axios from 'axios'

export default class Foundation {

  constructor(host, credentials={token:null, uid:null}) {
    this._host = host;
    this._token = credentials.token;
    this._uid = credentials.uid;
  }

  get authenticated () { return this._token != null; }
  get user_id () { return this._uid; }
  
  async _call({path, data, params, method, extra_headers}={method:'get'}) {

    try {
      const url = `${this._host}${path}`;
      const headers = {
        ...(this.authenticated ? {Authorization: `Token ${this._token}`} : {}),
        ...extra_headers
      };
      const response = await axios({method, url, data, params, headers});
      return response.data;
    } catch (e) {
      let message = '';
      switch(e.response.status) {
        case 400:
        case 401:
        case 403:
          message = 'Unauthorized';
          let data = e.response.data
          const error =  {
              name: 'UnauthorizedError',
              message: data.message,
              link: data.link
          };
          throw error;
          break;
        case 404:
          message = 'Not found';
          break
        default:
          message = 'Error';
      }
      throw new Error(`${message} (${e.response.status})`);
    }
  }

  /* Log in a user using: [username, password]
   * Most other calls will fail if not logged in.
   */
  async login(username, password) {
      let data = await this._call({path:`/login`, data:{username, password}, method:'post'});
      this._token = data.token;
      this._uid = data.uid;
      return data;
  }

  /* Log out the current logged in user */
  async logout() {
    this._token = null;
    this._uid = null;
  }

  /* Register a new user using values from: user_data and invite with id: invite_id. */
  async registerUser(invite_id, user_data) {
    return await this._call({path:`/invites/${invite_id}/register/`, method:'post', data:user_data})
  }

  /* Register a new user using values from: user_data and invite with id: invite_id. */
  async addUser(user_data) {
    return await this._call({path:`/register_user/`, method:'post', data:user_data})
  }

  /* Get a user profile.
   * If [id] is present the profile matching that will be fetched.
   * If [id] is not present the profile of the logged in user is fetched.
   */
  async getUser(uid=null) {
    uid = uid || this._uid;
    return await this._call({path:`/users/${uid}/`});
  }

  async getRoles() {
    if(!this._cached_roles)
      this._cached_roles = this._call({path:`/roles`});
    return this._cached_roles
  }

  async getActivity(p=1) {
    return await this._call({path:`/activity/?p=${p}`})
  }

  async resetActivityCache() {
    return await this._call({path:`/activity/clear/`, method: 'delete'})
  }

  async cancelSubscription() {
    return await this._call({path:`/cancel_subscription/`, method:'post'})
  }

  /* Media functions */
  async _getMedia(type) { return await this._call({path:`${type}`}); }
  async getImages() { return await this._getMedia('images'); }
  async getVideos() { return await this._getMedia('videos'); }

  async _addMedia(type, media, uid=null) {
    uid = uid || this._uid;
    return await this._call({path:`/${type}/`, data:{user_id:uid, original:media}, method:'post', extra_headers:{'Content-Type': 'multipart/form-data'}}); 
  }
  async addImage(image, uid=null) { return await this._addMedia('images', image, uid); }
  async addVideo(video, uid=null) { return await this._addMedia('videos', video, uid); }

  async _removeMedia(type, mediaId) { return await this._call({path:`/${type}/${mediaId}/`, method:'delete'});}
  async removeImage(imageId) { return await this._removeMedia('images', imageId) }
  async removeVideo(videoId) { return await this._removeMedia('videos', videoId) }

  /* Update the basic information of the logged in user with values from: [user_data] */
  async updateUser(user_data) {
    let uid = user_data.id || this._uid
    return await this._call({path:`/users/${uid}/`, data:user_data, method:'put'});
  }

  async queryUsers(search, page=0) {
    return await this._call({path:`/search/${search}/?p=${page}`});
  }

  /* Update a line of contact info for the logged in user.
   * data should contain [type, value (, id)]
   * id is optional. If present the contact is updated, otherwise created
   */
  async updateContact(contact, uid=null) {
    uid = uid || this._uid;
    return await this._call({path:`/contact_info/${uid}/`, data:contact, method:'post'});
  }

  /* Delete line of contact information for logged in user */
  async delContact(id, uid) {
    uid = uid || this._uid;
    return await this._call({path:`/contact_info/${uid}/${id}/`, method:'delete'});
  }

  async get_following(id, page) {
    return await this._call({path: `/follow/${id}/?p=${page}`, method:'get'});
  }
  
  /* Accept follow request with id: [id] */
  async accept(id) {
    return await this._call({path: `/follow/${id}/`, method:'post'});
  }

  /* Send follow request to user with id: [id] */
  async follow(id) {
    return await this._call({path: `/follow/${id}/`, method:'put'});
  }

  /* Unfollow user with id: [id] */
  async unfollow(id) {
    return await this._call({path: `/follow/${id}/`, method:'delete'});
  }

  /* Send invitation to: [email] using inviation with id: [invite_id] */
  async referUser(email, invite_id='', message='', send=true, promo='') {
    let path = invite_id.length > 0 ? `/invites/${invite_id}/send` : '/invites/send';
    return await this._call({path, method:'post', data:{email, message, send, promo}});
  }
  
  /* Locations */

  async searchPlaces(params) {
    return await this._call({path:`/places/`, params});
  }

  async getPlace(id) {
    return await this._call({path:`/places/${id}/`})
  }

  async getPlannedLocations(uid) {
    return await this._call({path:`/planner/location/${uid}/`, method:'get'});
  }

  async updatePlannedLocation(data) {
    return await this._call({path:`/planner/location/`, data:data, method:'post'});
  }

  async removePlannedLocation(id) {
    return await this._call({path:`/planner/location/${id}/`, method:'delete'});
  }

  async setLocation(data) {
    return await this._call({path: `/set_location/`, method:'post', data});
  }

  async requestPasswordReset(email) {
    return await this._call({path: `/lost_password/`, method:'post', data:{email}})
  }

  async resetPassword(id, data) {
    return await this._call({path: `/lost_password/${id}/`, method:'post', data});
  }


  /* Messaging */

  // list all channels.
  async getChannels() {
    return await this._call({path:`/messaging/`})
  }

  // Gets or creates a channel to a specific user_id
  async openChannel(user_id) {
    return await this._call({path:`/messaging/open/${user_id}/`, method:'post'})
  }

  // Gets a specific channel based on a channel_id
  async getChannel(channel_id) {
    return await this._call({path:`/messaging/${channel_id}`})
  }
  
  /* Opportunities */

  async listOpportunities() {
    let path = `/opportunities/`;
    return await this._call({path});
  }

  async getOpportunity(opportunity_id) {
    let path = `/opportunities/${opportunity_id}`;
    return await this._call({path});
  }

  async postOpportunity(data) {
    let path = `/opportunities/create`;
    return await this._call({path, data, method:'post'});
  }

  async updateOpportunity(opportunity_id, data) {
    let path = `/opportunities/${opportunity_id}`;
    return await this._call({path, data, method:'post'});
  }

  async deleteOpportunity(opportunity_id) {
    let path = `/opportunities/${opportunity_id}`;
    return await this._call({path, method:'delete'});
  }

  async getComments(opportunity_id) {
    let path = `/opportunities/${opportunity_id}/comments`;
    return await this._call({path});
  }

  async postComment(opportunity_id, text, reply_to=null) {
    let path = `/opportunities/${opportunity_id}/comments/post`;
    return await this._call({path, data:{text, reply_to}, method:'post'});
  }

  async deleteComment(opportunity_id, comment_id) {
    let path = `/opportunities/${opportunity_id}/comments/${comment_id}/delete`;
    return await this._call({path, method:'delete'});
  }

  /* Topics */

  async listTopics() {
    let path = `/topics/`;
    return await this._call({path});
  }

  async listThreads(topic_id, thread_start=null) {
    let path = `/topics/${topic_id}/`;
    if(thread_start) path += `${thread_start}/`
    return await this._call({path});
  }

  async getThread(thread_id) {
    let path = `/topics/thread/${thread_id}/`;
    return await this._call({path});
  }

  async postTopic(data) {
    let path = `/topics/post/`;
    return await this._call({path, data, method:'post'});
  }

  async postThread(topic_id, thread_start=null, data) {
    let path = `/topics/post/${topic_id}/`;
    if(thread_start) path += `${thread_start}/`
    return await this._call({path, data, method:'post'});
  }

  async deleteTopic(topic_id) {
    let path = `/topics/delete/${topic_id}`;
    return await this._call({path, method:'delete'});
  }

  async deleteThread(topic_id, thread_id) {
    let path = `/topics/delete/${topic_id}/${thread_id}`;
    return await this._call({path, method:'delete'});
  }

  async supportRequest(data) {
    let path = `/support/`;
    return await this._call({path, data, method:'post'});
  }

  async registerNotifications(data) {
    return await this._call({path:'/notifications/', method:'post', data})
  }

  async adminInviteStats() {
    return await this._call({path:'/invites/stats'})
  }

  async resolveApplication(id, accept) {
    return await this._call({path:`/invites/${id}/resolve`, method: accept ? 'post' : 'delete'})
  }
}


